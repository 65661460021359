export const tableOption = {
  searchMenuSpan: 6,
  columnBtn: false,
  border: true,
  selection: true,
  index: false,
  indexLabel: '序号',
  stripe: true,
  menuAlign: 'center',
  menuWidth: 350,
  align: 'center',
  refreshBtn: true,
  searchSize: 'mini',
  addBtn: false,
  editBtn: false,
  delBtn: false,
  viewBtn: false,
  props: {
    label: 'label',
    value: 'value'
  },
  column: [{
    label: '用户',
    prop: 'userId'
  }, {
    label: '日期',
    prop: 'orderDate'
  }, {
    label: '工单',
    prop: 'orderNo'
  }, {
    label: '联系人',
    prop: 'contactName'
  }, {
    label: '电话',
    prop: 'telephone'
  }, {
    label: '申请类型',
    prop: 'serviceType'
  }, {
    label: '描述',
    prop: 'serviceContent'
  }, {
    width: 150,
    label: '状态',
    prop: 'orderStatus',
    search: true,
    type: 'select',
    dicData: [
      {
        label: '待处理',
        value: 0
      }, {
        label: '已安排',
        value: 1
      }, {
        label: '处理中',
        value: 2
      }, {
        label: '处理完成',
        value: 3
      }
    ]
  }, {
    label: '备注',
    prop: 'remark'
  }]

}
